<template>
  <div class="mobile-banner" v-if="showMobileDownloadBanner">
    <div class="mobile-close" @click="closeMobile">
      <i class="material-icons notranslate">close</i>
    </div>
    <div class="mobile-container">

      <div class="app-logo">
        <img src="https://delicity.b-cdn.net/front/img/bag.svg">
      </div>
      <div class="app-description">
        <strong>APP DELICITY</strong>
        <span class="baseline">La plateforme équitable</span>
        <span class="info">pour iPhone & Android</span>
      </div>

    </div>
    <div class="mobile-action">
      <button @click="openMobile">Ouvrir</button>
    </div>
  </div>
</template>

<style lang="scss">
.mobile-banner
{
  height: 85px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .mobile-close
  {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 8px;
    i
    {
      font-size: 1.5em;
    }
  }

  .mobile-container
  {
    flex: 1;
    display: flex;
    align-items: center;
    .app-logo
    {
      border-radius: 10px;
      height: 58px;
      width: 58px;
      background-color: #00bffa;
      img
      {
        width: 58px;
        height: 58px;
      }
    }
    .app-description
    {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding-left: 10px;
      strong
      {
        font-size: 0.98em;
        font-weight: 600;
        line-height: 1;
      }
      .baseline
      {
        font-size: 0.8em;
        font-weight: 300;
        margin-top: 5px;
        line-height: 1;
      }
      .info
      {
        margin-top: 4px;
        font-size: 0.8em;
        font-weight: 300;
        line-height: 1;
        color: #afafaf;
      }
    }
  }

  .mobile-action
  {
    display: flex;
    align-items: center;
    padding-right: 10px;
    button
    {
      background-color: #00C2FF;
      border: 0;
      height: 27px;
      border-radius: 30px;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.85em;
      display: flex;
      align-items: center;
      padding: 0 14px;
    }
  }
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import moment from "moment";

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
    closeMobile(){
      localStorage.setItem('hideDownloadMobile', moment().unix());
      this.$store.commit('SET_SHOW_MOBILE_DOWNLOAD', false);
    },
    openMobile(){
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        window.open('https://t.delicity.co/KW9I1S', '_blank');
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.open('https://t.delicity.co/CSNBCW', '_blank');
      }
    }
  },
  mounted() {
    if(localStorage.getItem('hideDownloadMobile') && parseInt(localStorage.getItem('hideDownloadMobile')) + (60*10) > moment().unix() ){
      this.$store.commit('SET_SHOW_MOBILE_DOWNLOAD', false);
    }
  },
  computed: {
    ...mapGetters(['showMobileDownloadBanner'])
  }
}
</script>
